//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

$black:    #1D1D1B;

$primary-darker:      #00105E;
$primary-dark:        #001478;
$primary:             #273991;
$primary-light:       #B9C2ED;
$primary-lighter:     #E6EAFF;
$primary-lightest:    #F7F9FF;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary-darker": $primary-darker,
    "primary-dark": $primary-dark,
    "primary":    $primary,
    "primary-2":    $primary-2,
    "primary-3":    $primary-3,
    "primary-light":  $primary-light,
    "primary-lighter":  $primary-lighter,
    "primary-lightest":  $primary-lightest,
);

$theme-colors: (
  "primary-darker": $primary-darker,
  "primary-dark": $primary-dark,
  "primary":    $primary,
  "primary-2":    $primary-2,
  "primary-3":    $primary-3,
  "primary-light":  $primary-light,
  "primary-lighter":  $primary-lighter,
  "primary-lightest":  $primary-lightest,
);

// Body
//
// Settings for the `<body>` element.

$body-color:                $black;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Lato',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base:            $font-family-sans-serif;

$h2-font-size:                $font-size-base * 2.25;
$h3-font-size:                $font-size-base * 1.5;

$headings-font-family:        'Source Sans Pro', sans-serif;
$headings-font-weight:        700;
$headings-color:              $black;

$h3-font-weight:              600;

$display4-size:               3.375rem;

$display4-weight:             700;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.25rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 3),
    7: ($spacer * 4),
    8: ($spacer * 5),
    9: ($spacer * 7),
    10: ($spacer * 11)
  ),
  $spacers
);

// Components
//
// Define common padding and border radius sizes and more.

$border-radius:               .375rem;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               .9375rem;
$btn-padding-x:               1.875rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;

// Navbar

$navbar-light-color:                $primary;
$navbar-light-hover-color:          rgba($primary, .9);
$navbar-light-active-color:         rgba($primary, 1);
$navbar-light-disabled-color:       rgba($primary, .3);
$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-light-toggler-border-color: rgba($primary, .1);

// Breadcrumbs

$breadcrumb-border-radius:          $border-radius;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           40px;
