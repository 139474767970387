//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

//
//
// typography.scss
//
//

.display-4{
  line-height: 1.2;
}

h3, .h3 { font-weight: $h3-font-weight; }

.text-base {
  font-size: $font-size-base;
}

// Buttons

.btn {
  font-size: 1.125rem;
}

.btn-primary {
  color: $primary-lighter;
}

// Images

figure {
  text-align: left;
}

figcaption {
  color: $black;
}

// Borders

img.rounded, div.rounded{
  border-radius: 0.625rem !important;
}

//
//
// breadcrumb.scss
//
//

.breadcrumb {
  text-transform: uppercase;
}

.breadcrumb-item + .breadcrumb-item::before {
  width: 1.5rem;
  height: 1.5rem;
}

// Navbar

.navbar .nav-link {
  font-size: 16px;
  text-transform: uppercase;
}

@include media-breakpoint-up(lg) {
  .navbar .nav-link {
    font-size: 0.875rem;
  }
}

// Utilities

.inset-x-0 {
  right: 0;
  left: 0;
}

.z-0	{ z-index: 0; }
.z-10 { z-index: 10; }
.z-20 { z-index: 20; }
.z-30 { z-index: 30; }
.z-40 { z-index: 40; }
.z-50 { z-index: 50; }

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.vh-50 {
  height: 50vh;
}

@include media-breakpoint-up(lg) {
  .vh-lg-100 {
    height: 100vh;
  }
}

// Grid

@media all and (max-width: 767px){
  .row:not(.no-gutters){
    >[class*='col-6']{
      &:nth-child(odd){
        padding-right: $grid-gutter-width/4;
      }
      &:nth-child(even){
        padding-left: $grid-gutter-width/4;
      }
    }
  }
}

// Page header

.page-header {
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  height: 40vh;
}

@include media-breakpoint-up(md) {
  .page-header {
    padding-top: 60px;
    height: 30vh;
  }
}

@include media-breakpoint-up(lg) {
  .page-header {
    padding-top: 40px;
    height: 60vh;
  }
}

// Forms

.form-group label {
  color: $black;
}

// Flickity

.flickity-prev-next-button{
  background: $primary-lighter;
  border: 1px solid $primary-lighter;
  &.previous{
    left: 0;
  }
  &.next{
    right: 0;
  }
}

.signature {
  max-height: 80px;
}

@include media-breakpoint-up(md) {
  .signature {
    max-height: 100px;
  }
}